<template>
	<b-container fluid v-if="$checkPermission('modify-template')" id="attachmentPage">
		<cg-modal :data="newAttachmentFields" @update="confirmUploadAttachment"></cg-modal>

		<cg-loader :display="loading"></cg-loader>

		<cg-localized-alert :alertVariant="alertVariant" ref="cgLocAlert" :alertMessage="alertMessage"></cg-localized-alert>

		<div class="px-4 mb-4">
			<b-card-title class="mb-4">{{ $t('Navbar.Attachment') }}</b-card-title>

			<!-- Attachment Option -->
			<b-row align-h="end" class="mb-4">
				<b-col md="12" lg="8" align-self="end">
					
					<!-- New Attachment button -->
					<b-row align-h="end">
						<b-col cols="auto">
							<b-button @click="createNewAttachment()" size="sm" variant="primary" class="mb-3">
								<font-awesome-icon class="vertical-align-middle mr-2" :icon="['fas', 'plus']"/>{{ $t('Attachment.NewAttachment') }}
							</b-button>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		
			<b-table-simple
				id="attachmentListTable"
				ref="attachmentTable"
				sort-by.sync="id"
				sort-desc.sync="desc"
				:empty-text="$t('General.TableEmpty')"
				responsive="true" hover striped show-empty bordered>
				<b-thead>
					<b-tr>
						<b-th rowspan="2">{{ $t('Attachment.ID') }}</b-th>
						<b-th>{{ $t('Attachment.Description') }}</b-th>
						<b-th>{{ $t('Attachment.Language') }}</b-th>
						<b-th>{{ $t('General.Actions') }}</b-th>
					</b-tr>
				</b-thead>
				<b-tbody v-for="(attachment, index) in attachmentList" :key="index">
					<b-tr>
						<b-td :rowspan="attachment.data.length+1">{{attachment.id}}</b-td>
					</b-tr>
					<b-tr v-for="(a, i) in attachment.data" :key="i">
						<b-td>{{a.description}}</b-td>
						<b-td>{{$langs[a.language]}}</b-td>
						<b-td>
							<div class="table-row-center">
								<div class="action delete">
									<font-awesome-icon class="mx-2" :icon="['fas', 'download']"
										@click="downloadAttachment(a)"/>
								</div>
								<div class="action edit">
									<font-awesome-icon class="mx-2" :icon="['fas', 'pencil-alt']"
										@click="editAttachment(a)"/>
								</div>
							</div>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</div>

	</b-container>
</template>

<script>
import companyService from '../services/company.service';
import syspropService from '../services/sysprop.service';
import templateService from '../services/template.service';
import Vue from 'vue';

export default {
	data: function() {
		return {
			loading: false,
			searchTimeout: null,
			companyData: null,
			attachmentList: [],
			idOptions: [],
			companyId: localStorage.getItem('cg-company'),
			alertMessage: '',
			alertVariant: 'success',
			newAttachmentFields: {
				name: 'new-attachment',
				title: 'Attachment.NewAttachment',
				label: 'Attachment.NewAttachment',
				item: { language: this.$i18n.locale },
				submitHandler: "confirmUploadAttachment",
				fields:[
				{
						id: "id",
						label: 'Attachment.ID',
						type: "select",
						display: true,
						placeholder: 'Attachment.ID'
					},
					{ 
						id: "language",
						label: 'Attachment.Language',
						type: "select",
						required: true,
						display: true,
						placeholder: 'Attachment.Language',
					},
					{
						id: "name",
						label: 'Attachment.Name',
						type: "text",
						required: true,
						display: true,
						placeholder: 'Attachment.Name',
					},
					{ 
						id: "description",
						label: 'Attachment.Description',
						type: "text",
						required: true,
						display: true,
						placeholder: 'Attachment.Description',
					},
					{ 
						id: "file",
						label: 'Attachment.File',
						type: "file",
						display: true,
						placeholder: 'Attachment.SelectFile',
						acceptFileType: "application/*"
					}
				]
			},
			fields: []
		};
	},
	methods: {
		async getAttachments() {
			this.loading = true;

			try {
				let res = await templateService.getTemplateAttachments();
				console.debug("Attachment - getAttachments success", res);

				this.attachmentList = res.data.reduce((acc, obj) => {
					if (!acc[obj.id]) {
						acc[obj.id] = { id: obj.id, data: [] };
					}
					acc[obj.id].data.push(obj);
					return acc;
				}, {});

				const uniqueIds = [...new Set(res.data.map(obj => obj.id))];
				this.idOptions = uniqueIds;
				this.idOptions.unshift({ value: null, text: this.$i18n.t('Attachment.UploadNewDoc') });
			} catch (error) { 
				console.error("Attachment - getAttachments error", error);

				this.alertMessage = 'Attachment.RetrieveError';
				this.alertVariant = 'danger';
				this.$refs.cgLocAlert.showAlert();
			} finally {
				this.loading = false;
			}
		},
		createNewAttachment() {
			this.newAttachmentFields.title = 'Attachment.NewAttachment';
			this.newAttachmentFields.label = 'Attachment.NewAttachment';

			Vue.set(this.newAttachmentFields.fields.find(i => i.id === 'file'), 'required', true);
			Vue.set(this.newAttachmentFields.fields.find(i => i.id === 'id'), 'disabled', false);
			Vue.set(this.newAttachmentFields.fields.find(i => i.id === 'language'), 'disabled', false);

			this.cleanForm();
			this.$bvModal.show(this.newAttachmentFields.name);
		},
		editAttachment(record) {
			this.newAttachmentFields.title = 'Attachment.EditAttachment';
			this.newAttachmentFields.label = 'General.Save';

			Vue.set(this.newAttachmentFields, 'item', { ...record, isEdit: true });
			Vue.set(this.newAttachmentFields.fields.find(i => i.id === 'file'), 'required', false);
			Vue.set(this.newAttachmentFields.fields.find(i => i.id === 'id'), 'disabled', true);
			Vue.set(this.newAttachmentFields.fields.find(i => i.id === 'language'), 'disabled', true);

			this.$bvModal.show(this.newAttachmentFields.name);
		},
		async downloadAttachment(attachment) {
			const id = attachment.id;
			const language = attachment.language;
			const type = attachment.type;
			const name = attachment.name;

			console.debug("Downloading attachment", id, language, name, type);

			this.loading = true;

			try {
				const response = await fetch(`/api/template/attachments/content?id=${id}&language=${language}`);
				if (!response.ok) {
					throw new Error('Download failed');
				}
				
				const blob = await response.blob();
				const url = URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.download = name;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			} catch (err) {
				console.error("Attachment - downloadTemplateAttachment error", err);

				this.alertMessage = 'Attachment.DownloadError';
				this.alertVariant = 'danger';
				this.$refs.cgLocAlert.showAlert();
			} finally {
				this.loading = false;
			}
		},
		cleanForm() {
			this.newAttachmentFields.item = {
				name: null,
				description: null,
				language: this.$i18n.locale,
				file: null
			}
		},
		confirmUploadAttachment(attachment) {
			if(attachment.isEdit) {
				console.debug("Attachment is being edited, of course it exists already on the database and it's going to be overridden");
				this.addNewAttachment(attachment);
				return;
			}

			const id = attachment.id;
			const language = attachment.language;
			const existFile = this.attachmentList[id] && this.attachmentList[id].data.filter(el => el.language === language).length > 0;

			if(existFile) {
				console.debug("Attachment language for this ID already exists, ask permission to override");
				this.overrideAttachment(attachment);
			} else {
				console.debug("A brand new Attachment is going to be uploaded");
				this.addNewAttachment(attachment);
			}
		},
		overrideAttachment(attachment) {
			// This function displays a modal for the user to confirm the override
			this.$bvModal.msgBoxConfirm(this.$t("Attachment.ConfirmAttachmentOverrideMessage"), {
				title: this.$t("Attachment.AttachmentOverrideMessage"),
				size: 'md',
				buttonSize: 'md',
				okVariant: 'danger',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				// If the user confirms the override, value is not null
				if (value) {
					attachment.isEdit = true;
					this.addNewAttachment(attachment);
				}
			}).catch(err => {
				// The user canceled the action
				console.error("Attachment - confirmOverrideAttachment error", err);
			});
		},
		async addNewAttachment(newAttachment) {
			this.loading = true;

			let attachmentData = new FormData();
			attachmentData.append('attachment', newAttachment.file);
			attachmentData.append('name', newAttachment.name);
			attachmentData.append('description', newAttachment.description);
			attachmentData.append('language', newAttachment.language);
			attachmentData.append('id', newAttachment.id);

			let isEdit = newAttachment.isEdit;

			try {
				let res = await templateService.updateTemplateAttachment(attachmentData);
				console.debug("Attachment - manageAttachment success", res);

				this.alertMessage = isEdit ? 'Attachment.EditSuccess' : 'Attachment.CreateSuccess';
				this.alertVariant = 'success';

				this.getAttachments();
			} catch (error) { 
				console.error("Attachment - manageAttachment error", error);

				this.alertMessage = isEdit ? 'Attachment.EditError' : 'Attachment.CreateError';
				this.alertVariant = 'danger';
			} finally {
				this.loading = false;
				this.$refs.cgLocAlert.showAlert();
			}
		}
	},
	async created() {
		const config = await syspropService.getConfig();
		this.companyData = { contact: config.data.defaults.contact, gp_landing_url: config.data.defaults.gp_landing_url };

		if(this.companyId) {
			let companyRes = await companyService.getCompany(this.companyId);
			this.companyData.company = companyRes.data;
		}

		await this.getAttachments();

		this.fields = [
			{ key: 'id', label: this.$t('Attachment.ID'), sortable: true },
			{ key: 'name', label: this.$t('Attachment.Name'), sortable: true, class: "text-ellipsis" },
			{ key: 'description', label: this.$t('Attachment.Description'), sortable: true, class: "text-ellipsis" },
			{ key: 'language', label: this.$t('Attachment.Language'), sortable: true }
		];

		Vue.set(this.newAttachmentFields.fields.find(i => i.id === 'id'), 'options', this.idOptions);
		Vue.set(this.newAttachmentFields.fields.find(i => i.id === 'language'), 'options', this.$langs);
	}
};
</script>